import React from "react";

function Footer() {
    return (
        <footer className="footer mt-auto pt-5">
            <div className="container text-center">
                <p>Copyright SummitHosting &copy; 2022 | <a href="https://ticket.summithosting.com/hc/en-us" target="_blank" rel="noreferrer">Click here for SummitHosting support</a></p>
            </div>
        </footer>
    );
}

export default Footer;
