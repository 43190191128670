import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "globalthis/auto";
import "proxy-polyfill";
import "whatwg-fetch";
import "bootstrap/dist/css/bootstrap.css";
import Bowser from "bowser";

import * as Sentry from "@sentry/react";
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

const scrubbingPatterns = [
    { pattern: /\/reset\/[^/]+/, replacement: '/reset/__token__' },
    { pattern: /\/api\/checkToken\/[^/]+/, replacement: '/api/checkToken/__token__' },
    { pattern: /\/api\/resetPassword\/[^/]+/, replacement: '/api/resetPassword/__token__' },
    { pattern: /\/invite\/([^/]+)\/[^/]+/, replacement: '/invite/$1/__inviteUuid__' },
    { pattern: /\/api\/organizationLookup\/([^/]+)\/[^/]+/, replacement: '/api/organizationLookup/$1/__inviteUuid__' },
    { pattern: /\/api\/provisionUsers\/([^/]+)\/[^/]+/, replacement: '/api/provisionUsers/$1/__inviteUuid__' },
]

function beforeHandler(event) {
    for (const pattern of scrubbingPatterns) {
        if (event.request && event.request.url) {
            if (pattern.pattern.test(event.request.url)) {
                event.request.url = event.request.url.replace(pattern.pattern, pattern.replacement);
            }
        }

        if (event.transaction) {
            if (pattern.pattern.test(event.transaction)) {
                event.transaction = event.transaction.replace(pattern.pattern, pattern.replacement);
            }
        }

        if(event.breadcrumbs) {
            for(const breadcrumb of event.breadcrumbs) {
                if (breadcrumb.category === 'xhr' && pattern.pattern.test(breadcrumb.data.url)) {
                    breadcrumb.data.url = breadcrumb.data.url.replace(pattern.pattern, pattern.replacement);
                } else if (breadcrumb.category === 'fetch' && pattern.pattern.test(breadcrumb.data.url)) {
                    breadcrumb.data.url = breadcrumb.data.url.replace(pattern.pattern, pattern.replacement);
                } else if (breadcrumb.category === 'navigation') {
                    if (pattern.pattern.test(breadcrumb.data.from)) {
                        breadcrumb.data.from = breadcrumb.data.from.replace(pattern.pattern, pattern.replacement);
                    }
                    if (pattern.pattern.test(breadcrumb.data.to)) {
                        breadcrumb.data.to = breadcrumb.data.to.replace(pattern.pattern, pattern.replacement);
                    }
                }
            }
        }

        if (event.spans) {
            for (const span of event.spans) {
                if (span.op === 'http.client') {
                    if (pattern.pattern.test(span.description)) {
                        span.description = span.description.replace(pattern.pattern, pattern.replacement);
                    }

                    if (span.data) {
                        if (pattern.pattern.test(span.data.url)) {
                            span.data.url = span.data.url.replace(pattern.pattern, pattern.replacement);
                        }

                        if (pattern.pattern.test(span.data['http.url'])) {
                            span.data['http.url'] = span.data['http.url'].replace(pattern.pattern, pattern.replacement);
                        }
                    }
                }
            }
        }
    }

    return event;
}

const browser = Bowser.getParser(window.navigator.userAgent);
const isIE11OrLower = browser.satisfies({
    windows: {
        "internet explorer": "<=11"
    }
});

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    attachStacktrace: true,
    integrations: [
        new Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    tracesSampleRate: 0.5,
    replaysSessionSampleRate: isIE11OrLower ? 0.0 : 0.1,
    replaysOnErrorSampleRate: isIE11OrLower ? 0.0 : 1.0,
    beforeSend: beforeHandler,
    beforeSendTransaction: beforeHandler
});


ReactDOM.render(<App />, document.getElementById('root'));
