import {Component} from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default class Complete extends Component {
    render() {
        return <div className="complete">
            <Header />
            <section>
                <div className="jumbotron jumbotron-fluid py-5">
                    <div className="container text-center py-5">
                        <h2 className="display-4">Your users have been invited.</h2>
                        <h4>They will receive a follow up email from Summit support with their login information within 24 hours.</h4>
                    </div>
                </div>
            </section>
            <Footer/>
        </div>;
    }
}