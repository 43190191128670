import {Component} from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default class Home extends Component {
    render() {
        return <div className="home">
            <Header />
            <section>
                <div className="jumbotron jumbotron-fluid py-5">
                    <div className="container text-center py-5">
                        <h1 className="display-4">Welcome to SummitHosting!</h1>
                    </div>
                </div>
                <Footer />
            </section>
        </div>;
    }
}