import {Component} from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default class NoAccess extends Component {
    render() {
        return <div className="noaccess">
            <Header />
            <section>
                <div className="jumbotron jumbotron-fluid py-5">
                    <div className="container text-center py-5">
                        <h2>This site can only be accessed via your cloud desktop</h2>
                        <h4>If you cannot access your cloud desktop please open a <a href="https://ticket.summithosting.com/hc/en-us" target="_blank" rel="noreferrer">support ticket.</a></h4>
                    </div>
                </div>
            </section>
            <Footer/>
        </div>;
    }
}