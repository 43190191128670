import React from "react";
import { Link } from "react-router-dom";

function Header() {
    return (
        <header>
            <nav className="navbar navbar-expand-md fixed-top navbar-dark bg-dark">
                <Link className="navbar-brand" to="/">
                    <img src={process.env.PUBLIC_URL + '/summit_whitecolor.png'} alt="Summit" height="56" />
                </Link>
            </nav>
        </header>
    );
}

export default Header;
