import {Component} from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default class InviteNotFound extends Component {
    render() {
        return <div className="invite-not-found">
            <Header />
            <section>
                <div className="jumbotron jumbotron-fluid py-5">
                    <div className="container text-center py-5">
                        <h2>This invite link was not found</h2>
                        <h4>Please open a <a href="https://ticket.summithosting.com/hc/en-us" target="_blank" rel="noreferrer">support ticket to receive a new one.</a></h4>
                    </div>
                </div>
            </section>
            <Footer/>
        </div>;
    }
}