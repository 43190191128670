import React from "react";
import Bowser from "bowser";


function Spinner({ message = "Loading..." }) {
    const browser = Bowser.getParser(window.navigator.userAgent);
    const isIE11OrLower = browser.satisfies({
        windows: {
            "internet explorer": "<=11"
        }
    });

    if (!isIE11OrLower) {
        return (
            <div className="spinner-border text-success spinner-custom" role="status">
                <span className="sr-only">{message}</span>
            </div>
        );
    } else {
        return (
            <div role="status">
                <img src="/spinner.gif" width="50" alt={message} />
            </div>
        );
    }
}

export default Spinner;
